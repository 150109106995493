<template>
	<div class="result">
		<div class="inner" v-if="orderData">
			<div class="labelView">
				<div class="txt" @click="$router.push('/')">
					{{i18n('02')}}
				</div>
				<div class="gang">
					/
				</div>
				<div class="txt">
					{{i18n('32')}}
				</div>
			</div>
			<div class="zt">
				<img src="@/assets/success.png" alt="" v-if="type==1">
				<img src="@/assets/error.png" alt="" v-if="type==2">
				<div class="one" v-if="type==1">
					{{i18n('204')}}
				</div>
				<div class="one" v-if="type==2">
					{{i18n('205')}}
				</div>
				<div class="two" v-if="type==1">
					{{i18n('206')}}
				</div>
				<div class="two" v-if="type==2">
					{{i18n('207')}}
				</div>
				<div class="but">
					<div class="but11" v-if="type==1 && orderData.pack1!=0" @click="$router.push('/orderDetails?order_id='+orderData.pack1)">
						{{i18n('100')}}
					</div>
					<div class="but11" v-if="type==1 && orderData.pack2!=0" @click="$router.push('/orderDetails?order_id='+orderData.pack2)">
						{{i18n('85')}}
					</div>
					<div class="but11" v-if="type==2" @click="PayAgen">
						{{i18n('183')}}
					</div>
					<div class="but22" @click="$router.replace('/')">
						{{i18n('208')}}
					</div>
				</div>
			</div>
			<div class="info">
				<!-- <div class="left"> -->
					<!-- <shxx :orderData="orderData"></shxx> -->
					<ddzy :orderData="orderData"></ddzy>
				<!-- </div> -->
			</div>
			<div class="jrtj">
				<div class="">
					{{i18n('141')}}
				</div>
			</div>
			<div class="list">
				<div class="item" v-for="(item,index) in listData" :key="index">
					<commodity :data='item'></commodity>
				</div>
			</div>
		</div>
		<foot v-if="orderData"></foot>
	</div>
</template>

<script>
	import {
		orderResult,
		// payagain
	} from "@/api/order.js"
	import {
		commodityHome
	} from "@/api/commodity.js"
	
	import ddzy from "./components/ddzy.vue"
	import commodity from "@/components/commodity.vue"
	import foot from "@/components/topBot/footer.vue"
	export default {
		name: "result",
		components:{
			ddzy,
			commodity,
			foot
		},
		data() {
			return {
				commodityHomeParams: {
					type: 1, //1精选好货  //2特价商品  //3预定商品
					page_num: 1,
					page_size: 20
				},
				listData:[],
				params: {
					set_id: 0,
					pay_method: 0,
					source: 7,
					open_id: ""
				},
				loding: false,
				orderData: false,
				type: 1 //1成功    2失败
			}
		},
		methods: {
			PayAgen(){
				this.$router.push('/pay?set_id='+this.params.set_id+'&pay_price='+this.orderData.pay_price)
			},
			async orderResult(set_id) {
				let {
					data
				} = await orderResult({
					set_id
				})
				this.orderData = data
				if (data.status == 1) {
					this.type = 1
				} else {
					this.type = 2
				}
			},
			//获取推荐商品列表
			async commodityHome() {
				let {
					data
				} = await commodityHome(this.commodityHomeParams)
				this.listData = data
			},
		},
		computed:{
			
		},
		created(){
			this.$store.commit('user/setClassifyShow', false)
			this.params.set_id=Number(this.$route.query.set_id)
			this.commodityHome()
			this.orderResult(this.params.set_id)
		},
		mounted(){
			
		}
	};
</script>

<style scoped>
	.list {
		flex: 1;
		width: 1200px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}
	
	.list .item {
		margin-bottom: 16px;
		margin-right: 22px;
	}
	
	.list .item:nth-child(5n) {
		margin-right: 0;
	}
	.jrtj{
		width: 1200px;
		height: 42px;
		background: #F3F3F3;
		border: 1px solid rgba(86,171,123,0.1);
		margin-bottom: 24px;
	}
	.jrtj div{
		width: 99px;
		height: 42px;
		background: #56AB7B;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #FFFFFF;
		text-align: center;
		font-weight: 400;
		line-height: 42px;
	}
	.info{
		margin-bottom: 24px;
		display: flex;
	}
	.zt{
		margin-bottom: 24px;
	}
	.zt img{
		width: 100px;
		height: 100px;
		display: block;
		margin: 0 auto;
	}
	.zt .one{
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: #272E3B;
		letter-spacing: 0;
		font-weight: 500;
		text-align: center;
		margin-bottom: 8px;
	}
	.zt .two{
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #272E3B;
		letter-spacing: 0;
		font-weight: 400;
		margin-bottom: 24px;
		text-align: center;
	}
	.zt .but{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.zt .but div{
		cursor: pointer;
	}
	.zt .but div.but11{
		width: 110px;
		height: 32px;
		border: 0.5px solid rgba(86,171,123,1);
		border-radius: 16px;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #1D2129;
		text-align: center;
		font-weight: 400;
		line-height: 32px;
		margin-right: 10px;
	}
	.zt .but div.but22{
		width: 110px;
		height: 32px;
		background: #56AB7B;
		border-radius: 16px;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #F8F8F8;
		text-align: center;
		font-weight: 400;
		line-height: 32px;
	}
	.labelView {
		padding-top: 24px;
		display: flex;
		align-items: center;
	}
	
	.labelView .txt {
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #4E5969;
		line-height: 22px;
		font-weight: 400;
	}
	
	.labelView .gang {
		font-size: 12px;
		padding: 0 6px;
		opacity: .6;
		line-height: 22px;
	}
	.inner{
		background: #F5F5F5;
		border-radius: 0 0 20px 20px;
		padding: 0 50px;
		box-sizing: border-box;
	}
	.result{
		flex: 1;
		height: 100%;
		overflow-y: scroll;
	}
</style>