<template>
	<div class="endways">
		<div class="bg" @click="commodityDetails(data.base.id)">
			<img :src="data.base.cover" alt="" class="bb" mode="widthFix">
			<!-- <image src="@/static/rm.png" mode="" class="jb"></image> -->
			<div class="sx" v-if="isSx">
				<img src="@/assets/bh.png" mode="widthFix">
			</div>
		</div>
		<div class="info" @click="$router.push('/commodityDetails?id='+data.base.id)">
			<div class="name over2">
				<!-- <span
					v-if="data.base.delivery_modeint==2">{{i18n('05')}}</span> -->
				{{lang=='zh'?data.base.zh_name:lang=='en'?data.base.en_name:data.base.vi_name}}
			</div>
			<div class="dis over">
				{{lang=='zh'?data.base.zh_selling_point:lang=='en'?data.base.en_selling_point:data.base.vi_selling_point}}
			</div>
		</div>

		<div class="cz">
			<div class="price">
				<span class="dw">₱</span>{{data.sku[0].price}}<span
					class="gg">/{{lang=='zh'?data.sku[0].zh_name:lang=='en'?data.sku[0].en_name:data.sku[0].vi_name}}</span>
			</div>
			<div class="adAn" @click="addCart" v-if="!isSx">
				<img src="@/assets/cart.png" alt="" mode="widthFix">
				<div class="number" v-if="isCart">
					{{addCartNumber}}
				</div>
			</div>
			<!-- <div class="jjcz" v-if="!isSx && isCart">
				<div class="jian">
					<image src="@/static/jian.png" alt=""></image>
				</div>
				<div class="num">
					{{addCartNumber}}
				</view>
				<div class="jia">
					<image src="@/static/jia.png" alt=""></image>
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
	export default {
		props: ['data'],
		data() {
			return {
				addCartNumber: 0
			}
		},
		computed: {
			purchase_limit() {
				let purchase_limit = 10000000000
				if (this.data.base.purchase_limit > 0) {
					purchase_limit = this.data.base.purchase_limit
				}
				return purchase_limit
			},
			lang() {
				return this.$store.state.i18n.lang
			},
			isSx() {
				if (this.data) {
					if (this.data.base.stock <= 0) {
						return true
					} else {
						let stock = 0
						if (this.data) {
							this.data.sku.map(item => {
								if (item.stock_type == 0) {
									stock = stock + parseInt(this.data.base.stock / item.quantity)
								} else {
									stock = stock + parseInt(this.data.base.stock / (item.quantity * this.data
										.unit_info.proportion))
								}
							})
						}
						if (stock == 0) {
							return true
						} else {
							return false
						}
					}
				} else {
					return false
				}
			},
			cartData() {
				if (this.data) {
					if (this.data.base.delivery_modeint == 1) {
						return this.$store.state.cart.forthwith
					} else {
						return this.$store.state.cart.reserve
					}
				} else {
					return []
				}
			},
			isCart() {
				let addCartNumber = 0
				if (this.data) {
					if (this.data.sku.length == 1) {
						let data = this.cartData.find(item => item.base.id == this.data.base.id)
						if (data) {
							addCartNumber = data.Count
						} else {
							addCartNumber = 0
						}
					} else {
						this.cartData.map(item => {
							if (item.base.id == this.data.base.id) {
								addCartNumber = addCartNumber + item.Count
							}
						})

					}
				}
				this.addCartNumberInit(addCartNumber)
				return addCartNumber
			},
		},
		mounted() {

		},
		methods: {
			commodityDetails(id) {
				if (this.$route.path != '/commodityDetails') {
					this.$store.commit('user/setClassifyShow', false)
				}
				this.$router.push('/commodityDetails?id=' + id)
			},
			addCartNumberInit(addCartNumber) {
				this.addCartNumber = addCartNumber
				if (this.data.sku.length == 1) {
					let data = this.cartData.find(item => item.base.id == this.data.base.id)
					if (data) {
						this.data.CartId = data.CartId
					}
				}
			},
			//添加购物车
			async addCart() {
				if (!this.$store.state.user.token) {
					this.$router.push('/loginRegistration?type=1')
					return false
				}
				this.addCartNumber = Number(this.addCartNumber)
				let stock = 0
				if (this.data.sku[0].stock_type == 0) {
					stock = parseInt(this.data.base.stock / this.data.sku[0].quantity)
				} else {
					stock = parseInt(this.data.base.stock / (this.data.sku[0].quantity * this.data
						.unit_info.proportion))
				}


				if (this.data.sku.length == 1) {
					// 直接 满足 库存就加入
					let number = this.addCartNumber + 1
					if (number > stock) {
						this.$message.error(this.i18n('81'))
					} else if (number > this.purchase_limit) {
						this.$message.error(this.i18n('80') + this.purchase_limit)
					} else {
						this.addCartNumber = number
						let params = JSON.parse(JSON.stringify(this.data))
						params.Count = number
						params.skuAc = this.data.sku[0]
						await this.$store.dispatch("cart/addCart", params)
						// this.$message.success('添加成功')
					}
				} else {
					//去选择规格
					let params = JSON.parse(JSON.stringify(this.data))
					this.$store.commit("cart/setSeleAddCartData", params)
					this.$store.commit('cart/setAddCartPopup', true)
				}
			}
		}
	}
</script>

<style scoped>
	.sx {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		overflow: hidden;
		border-radius: 20rpx;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(0, 0, 0, 0.30);
	}

	.sx img {
		width: 200px;
		display: block;
	}

	.jian,
	.jia {
		width: 50rpx;
		height: 50rpx;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.jian img,
	.jia img {
		width: 32rpx;
		height: 32rpx;
		display: block;
	}

	.jian {
		border-right: 1rpx solid rgba(193, 193, 193, 1);
	}

	.jia {
		border-left: 1rpx solid rgba(193, 193, 193, 1);
	}

	.jjcz {
		width: 160rpx;
		height: 50rpx;
		background: #FFFFFF;
		border: 1rpx solid rgba(193, 193, 193, 1);
		border-radius: 8rpx;
		display: flex;
	}

	.adAn {
		width: 36px;
		height: 36px;
		background-image: linear-gradient(180deg, #B5D58F 0%, #56AB7B 100%);
		border-radius: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
	}

	.adAn img {
		width: 23.4px;
		height: 23.4px;
	}

	.adAn .number {
		padding: 0 7px;
		height: 20px;
		background: #F1554D;
		border-radius: 10px;
		position: absolute;
		right: -5px;
		top: -10px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #FFFFFF;
		font-weight: 400;
	}

	.cz {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 10px;
	}

	.cz .price {
		font-family: PingFangSC-Medium;
		font-size: 20px;
		color: #F23725;
		font-weight: 500;
	}

	.cz .price .dw {
		font-family: Helvetica;
		font-size: 14px;
		color: #F23725;
		font-weight: 400;
	}

	.cz .price .gg {
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #B7B7B7;
		font-weight: 400;
	}

	.info {
		padding: 6px 10px 0 10px;
		height: 65px;
		box-sizing: border-box;
	}

	.dis {
		font-family: PingFangSC-Regular;
		font-size: 15px;
		color: #56AB7B;
		font-weight: 400;
	}

	.name {
		font-family: PingFangSC-Medium;
		font-size: 16px;
		color: #1D2129;
		line-height: 18px;
		font-weight: 500;
		padding-bottom: 4px;
	}

	/* .name span {
		padding: 0 4rpx;
		margin-right: 6rpx;
		height: 28rpx;
		background: #F23725;
		border-radius: 4rpx;
		font-family: PingFangSC-Regular;
		font-size: 20rpx;
		color: #FFFFFF;
		font-weight: 400;
		vertical-align: text-bottom;
	} */

	.bg {
		width: 220px;
		height: 220px;
		position: relative;
		overflow: hidden;
	}

	.bg img.bb {
		width: 220px;
		height: 220px;
		display: block;
	}

	.bg img.jb {
		position: absolute;
		left: 0;
		top: 0;
		width: 114rpx;
		height: 112rpx;
	}

	.endways {
		width: 220px;
		height: 340px;
		background: #FFFFFF;
		border-radius: 8px;
		border: 0.5px solid transparent;
		-moz-column-break-inside: avoid;
		-webkit-column-break-inside: avoid;
		break-inside: avoid;
		overflow: hidden;
		cursor: pointer;
	}

	.endways:hover {
		border: 0.5px solid rgba(1, 126, 61, 1);
	}
</style>
