<template>
	<div class="ddzy">
		<div class="tit">
			{{i18n('179')}}
		</div>
		<div class="list">
			<div class="item">
				<div class="left">
					{{i18n('180')}}：
				</div>
				<div class="right">
					{{orderData.order_sn}}
				</div>
			</div>
			<div class="item">
				<div class="left">
					{{i18n('181')}}：
				</div>
				<div class="right" v-if='orderData.pay_method==1'>{{i18n('200')}}</div>
				<div class="right" v-if='orderData.pay_method==2'>{{i18n('201')}}</div>
				<div class="right" v-if='orderData.pay_method==3'>{{i18n('202')}}</div>
				<div class="right" v-if='orderData.pay_method==4'>{{i18n('203')}}</div>
				<div class="right" v-if='orderData.pay_method==6'>GrabPay</div>
				<div class="right" v-if='orderData.pay_method==7'>Gcash</div>
				<div class="right" v-if='orderData.pay_method==8'>VISA</div>
			</div>
			<div class="item">
				<div class="left">
					{{i18n('167')}}：
				</div>
				<div class="right" style="color: #F23725;" v-if="orderData.distribution_fee!=0">
					<span>₱</span>{{orderData.distribution_fee}}
				</div>
				<div class="right" style="color: #F23725;" v-else>
					{{i18n('182')}}
				</div>
			</div>
			<div class="item">
				<div class="left">
					{{i18n('168')}}：
				</div>
				<div class="right" style="color: #F23725;">
					<span>₱</span>{{orderData.coupon_price}}
				</div>
			</div>
		</div>
		<div class="list" style="border-bottom: none;">
			<div class="item">
				<div class="left">
					{{i18n('169')}}：
				</div>
				<div class="right" style="color: #F23725;">
					<span>₱</span>{{orderData.pay_price}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props:['orderData'],
		name: "ddzy",
		components: {
			
		},
		data() {
			return {
				
			}
		},
		computed: {
		},
		methods: {
			
		},
		mounted() {
	
		}
	};
</script>

<style scoped>
	.list{
		width: 760px;
		margin: 0 auto;
		padding: 12px 0;
		border-bottom: 1px solid #e8e8e8;
	}
	.item{
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 48px;
	}
	.item .left{
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #86909C;
		font-weight: 400;
	}
	.item .right{
		font-family: PingFangSC-Regular;
		font-size: 18px;
		color: #1D2129;
		text-align: right;
		line-height: 28px;
		font-weight: 400;
	}
	.item .right span{
		font-size: 12px;
	}
	.ddzy{
		width: 800px;
		background: #FFFFFF;
		border-radius: 9px;
		padding-bottom: 20px;
		margin: 0 auto;
	}
	.tit{
		width: 100%;
		padding-left: 24px;
		height: 48px;
		line-height: 48px;
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: #1D2129;
		font-weight: 500;
		border-bottom: 1px solid #56AB7B;
		box-sizing: border-box;
	}
</style>